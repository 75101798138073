var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f8083fdb5cbd7cb21eb0f50b2cef00398fe0ea53"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_NEXT_DSN,
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 150,
  debug: false,
  enableAutoSessionTracking: true,
  environment: process.env.SENTRY_NEXT_ENVIRONMENT,
});
